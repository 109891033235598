import './App.css';
import image from './image_full.png'
import {useEffect} from "react";
function App() {

    const pathName = window.location.pathname;

    useEffect(() => {
        const customerId = pathName.replaceAll("/","");
        if(customerId) {
            const cbretention = new window.ChargebeeRetention({
                api_key: 'eGobXg5v7N',
            });

            cbretention.init({
                customer_id: customerId,
                user: {
                    email: 'carl.nightinga@chargebee.com',
                    roles: ['Product']
                },
            });
        }
    },[pathName]);


  return (
    <div className="App">
      <img style={{'minHeight':'800px'}} width='100%' height='100%' src={image}/>
    </div>
  );
}

export default App;
